<template>
  <Header />
  <div class="row text-left q-mt-md q-mt-xl">
    <div class="col-md-6 page-container control-panel-container">
      <div class="flex justify-between">
        <h6 class="q-mt-xs q-mb-xs q-ml-md text-weight-bold text-left">
          {{ $t("ControlPanel.wallet") }}
        </h6>
        <LogOut />
      </div>

      <q-card class="my-card text-left q-ma-md q-pa-md">
        <q-card-section class="no-border q-pb-none q-pt-none">
          <div style=" display: flex; justify-content: space-between; align-items: center;">
            <p class="q-ma-none">{{ $t("General.balance") }}</p>
            <router-link to="/transacciones">
              <q-card-section class="underline-text title-transacciones q-pa-sm text-weight-bold">
                {{ $t("Home.see-movements") }}
              </q-card-section>
            </router-link>
          </div>
          <div class="text-h4 text-weight-bold">
            {{ points.points.points }}
            {{ settings.currency_abbreviation }}
          </div>
          <p class="q-mt-md q-pa-none">
            {{ $t("ControlPanel.card-number") }}:
            <span class="text-weight-bold">{{ user.card }}</span>
          </p>

        </q-card-section>
      </q-card>

      <q-card class="my-card text-left q-ma-md q-pa-md">
        <q-card-section class="no-border q-pb-none q-pt-none">
          <p class="text-h6 text-weight-bold" style="text-align: center;">
            {{ $t("ControlPanel.download-card") }}
          </p>
          <div 
            class="q-mt-md pass-download-card"
            style="display: flex; align-items: center; justify-content: center;"
          >
            <div>
              <a :href="user.ios_pass" target="_blank">
                <q-img src="@/assets/images/ios-pass.png" spinner-color="white" class="q-my-sm q-mx-sm"
                  style="width: 135px;" />
              </a>
            </div>
            <div>
              <a :href="user.google_wallet_pass" target="_blank">
                <q-img src="@/assets/images/google-wallet-pass.png" spinner-color="white" class="q-my-sm q-mx-sm"
                  style="width: 155px;" />
              </a>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
  <Footer />
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapState, mapActions } from "vuex";
import settings from "@/mixins/settings";

const Header = defineAsyncComponent(() =>
  import("../../shared/components/Header.vue")
);

const Footer = defineAsyncComponent(() =>
  import("../../shared/components/Footer.vue")
);

export default {
  mixins: [settings],

  name: "Home",
  components: {
    Header,
    Footer,
  },
  

  methods: {
    ...mapActions("points", ["getPoints"]),

    ...mapActions("transactions", ["getTransactions"]),
    
  },

  computed: {
    
    ...mapState("auth", ["user"]),

    points() {
      return this.$store.state.points.points;
    },
    transactions() {
      return this.$store.state.transactions.transactions;
    },
  },

  // mounted() {
  //   
  // },
};
</script>
<style lang="scss" scoped>
.q-card {
  border-radius: 25px 25px 25px 0px;
}

.title-transacciones {
  color: v-bind("settings.color_accent");
}

@media only screen and (max-width: 767px) {
  /*.pass-download-card {
      flex-direction: column
  }*/

  .control-panel-container {
    width: 100%;
  }
}


</style>
